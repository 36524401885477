@import url(//fonts.googleapis.com/css?family=Inconsolata:400,700|Montserrat:700);

html {
  font: 16px/1.5 Inconsolata, sans-serif;
}

@media (min-width: 30rem) {
  html {
    font-size: 20px;
  }
}

body {
  margin: 2rem 0 5rem;
  color: #333;
}


@media (min-width: 30rem) {
  body {
    margin-top: 5rem;
  }
}

a {
  color: #0074d9; /* From http://clrs.cc */
  text-decoration: none;
}

a:hover, a:focus {
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, sans-serif;
  margin: 0 0 0.5rem -0.1rem /* align left edge */;
  line-height: 1;
  color: #111;
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

@media (min-width: 30rem) {
  h1 {
    font-size: 3rem;
    margin-bottom: 3rem;
  }
}

h1 a {
  color: inherit;
}

h2 {
  margin-top: 2rem;
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
}

@media (min-width: 30rem) {
  h2 {
    margin-top: 2.5rem;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

h3, h4, h5, h6 {
  margin-top: 1.5rem;
  font-size: 1rem;
  text-transform: uppercase;
}

p, ul, ol, dl, table, pre, blockquote {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul, ol {
  padding-left: 1.5rem;
}

dd {
  margin-left: 1.5rem;
}

blockquote {
  margin-left: 0;
  margin-right: 0;
  padding: .5rem 1rem;
  border-left: .25rem solid #ccc;
  color: #666;
}

blockquote p:last-child {
  margin-bottom: 0;
}

hr {
  border: none;
  margin: 1.5rem 0;
  border-bottom: 1px solid #ccc;
  position: relative;
  top: -1px;
}

.container img, .container iframe {
  max-width: 100%;
}

.container img {
  margin: 0 auto;
  display: block;
}

table {
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

td, th {
  padding: .25rem .5rem;
  border: 1px solid #ccc;
}

pre, code {
  font-family: inherit;
  background-color: #eee;
}

pre {
  padding: .5rem 1rem;
  font-size: 0.8rem;
}

code {
  padding: .1rem .25rem;
}

pre > code {
  padding: 0;
}

.container {
  max-width: 30rem;
  margin: 0 auto;
  padding: 0 1rem;
}

.masthead {
  margin-bottom: 30px;
}
.masthead img {
  width: 300px;
  border-radius: 50%;
  margin: 0 auto;
  display: table;
}

.social-columns ul li {
  list-style: none;
  text-align: left;
  display: inline;
}

.social-columns {
  margin: 0 auto;
  text-align: center;
}

.label-default {
  background-color: lightgray;
  border-radius: 5px;
  padding: 10px;
  color: white;
}

.post-title {
  cursor: pointer;
}

.labels {
  margin-top: 10px;
}